@import './mixins.scss';
@import './variables.scss';


// @keyframes textRoll {
//   0% {
//     transform: translateX(50%);
//   }
//   100% {
//     transform: translateX(-100%);
//   }
// }
@keyframes textRoll {
100% { margin-left: 105vw; left:auto;opacity:1; }
60% { margin-left: -125%;opacity:1;}
61%,99% {
  opacity:0;
}

50% { margin-left: -125%;}

}

.banner {
  width: 100%;
  // margin-bottom: $margin;
  position: relative;
  // z-index: 150;
  // max-height: 100vh;
  @include flex();
  // padding: $gutter*2.5 0 0;
  padding-top: 16vh;
  padding-top: 0;
  background: $color-banner-bg;
  // padding-bottom: 10vw;
overflow: hidden;

  .title-wrapper {
    position: absolute;
    top: 1vh;
    margin: auto;
    padding: $padding;
    // left: 0;
    
  }

  .title--banner {
    font-size: 30vw;
    
    line-height: 0.75;
    @include flex($dir:column);
    color: $white;

    .banner__subtitle {
      font-size: calc(30px + 2.25vw);
      row-gap: .75rem;
      padding: $padding $padding 0;
    }

    .plus {
      margin: {
        left:$gutter;
        right:$gutter;
      }
    }

    span {
      &:last-child {
        // letter-spacing: -2px;
        letter-spacing: -0.035em;
      }
    }
  }

  p {
    font-size: 1.4rem;
    max-width: 700px;
    text-align: center;
    margin-bottom: 10vw;
  }


  video,img, iframe {
    width: 100%;
    max-height: 100vh;
    object-fit: cover;
    max-width: 70vw;
    margin: 0 auto;
    display: none;
  }

  @include mqTo($portrait){
    // padding-top: 10vh;
  }

  @include mqFrom($portrait){
    // height: 375vh;
    // @include flex($align:flex-start);
    // margin-bottom: $gutter*2.5;


  }

  .svg-text {
    width: 100vw;
    // height: 100vw;
    img, video {
      display: block;
      width: 100%;
      max-width: 100vw;
      max-height: none;
      object-fit: contain;

      // transform: translateY(-20vh)
    }
  }


  &__content {
    @include flex();

    .title--huge {
      color:$red;
      color:$blue--dark;
      max-width: 90vw;
      // transform: translateY(-50%);
      // margin-left: 10vw;

      // If textRoll
      // font-size: 45vw;
      // display: inline-flex;
      // flex-wrap: nowrap;
      white-space: nowrap;
      max-width: none !important;
      // width: auto;
      // transform: none;
      // margin-bottom: 5vw;
      //
      // span {
      //   animation: textRoll 30s infinite linear;
      //   animation-delay: 3s;
      //   margin-left: 0vw;
      //   max-width: none !important;
      //
      //
      //   &:last-child {
      //     animation-delay: 18s;
      //     position: absolute;
      //     left:100vw;
      //   }
      // }

      // text-shadow: 3300px 0 0 red;
      // transform: translateX(50%);
      // animation-play-state: paused;

      &:hover {
        // animation-play-state: running;
        // animation-play-state: paused;
      }

    }

    @include mqFrom($portrait){
      // position: absolute;
      top:0;
      left: 0;
      right: 0;
      // bottom: 0;
      // z-index: 155;
      // transform: translateY(1rem);


      .title--huge {
        // color:$red;
        max-width: 100vw;
        font-size: 52vw;

      }
    }
    @include mqFrom($xl){
      // transform: translateY(27%);

      .title--huge {
        font-size: 40vw;
        line-height: .8;
      }
    }

  }
}
