@import './buttons.scss';

html, body {
  font-size: $font-size;
  line-height: $line-height;
}
::selection {
  // background: $color-lime;
  background: $color-2023-blue;
}

html {
  padding-top: 0 !important;
}

body {
  background: $color-bg;
  position: relative;
  margin: 0;
  color: $color-text;
  font-family: $font-normal;

  &.lock {
    overflow: hidden;
  }

}

* {
  box-sizing: border-box;

}

figure {
  margin:0;
  padding: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
}

h1, h2, h3, h3, h4, h5, h6 {
  // font-family: "DIN Condensed";
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: $padding;
  line-height: $line-height-heading;
  font-weight: normal;
}

[class*="title"]{
  // font-family: $font-title;
  // font-weight: 700;
  // @include fontBold();
  @include fontTitle();

}

.link-download {

  a {
    text-decoration: none;
  }

  .single-article & {
    position: absolute;
    right: 3vh;
    top:3vh;
  }
}

.title {
  &--mission {
    @include fontTitle($size:calc(35vw));
    margin:0;
    white-space: nowrap;

    @include mqFrom($desktop){
      @include fontTitle($size:calc(6rem + 25vw));

    }

    @include mqFrom($xl){
      font-size: 35vw;
    }
  }

  &--group {
    @include fontTitle($size:calc(1.6rem + 5vw));
    line-height: .9;
    margin:$padding 0;
    white-space: nowrap;

    @include mqFrom($desktop){
      @include fontTitle($size:calc(3rem + 7.5vw));
      margin:$padding*3 0;

    }
  }

  &--huge {
    @include fontTitle();

    font-size: calc(2rem + 14vw);

    @include mqFrom($desktop){
      font-size: 19vw;
    }

  }
  &--large {
    @include fontTitle();

    font-size: calc(2rem + 3vw);
    // font-size: 2.4rem;

  }
  &--medium {
    @include fontTitle($size:2.4rem);
    @include mqFrom($desktop){
      font-size: 4.5rem;
    }
  }
  &--small {
    @include fontTitle($size:2.4rem);
  }
}

.text-purple {
    color: $color-2023-purple;
}

.line-before {
  @include flex();
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: "";
    height: 4px;
    width: 30px;
    background: $color-text;
    display: block;
    // margin-right: .5rem;
  }
}

.flex {
  @include flex();
}
.flex-col {
  @include flex($dir:column, $align:start);
}

.diagrams {
  width: 100%;
  transition: all .5s $bezier 0s;
  filter: blur(40px);
  transform: scale(.8);

  .scroll-point--active & {
    transition: all 1s $bezier .5s;
    transform: none;
    filter: none;
  }



  figure {
    flex:1;
    margin: 0;

    img, svg {
      height: 320px;
      object-fit: cover;

      width: 320px;
      height: auto;

      @include mqFrom($desktop){
        // height: 420px;
        // height: 500px;
        width: 500px;
        height: auto;

      }
    }
  }

  @include mqFrom($portrait){
    // margin-top: $margin;
    @include flex($align:space-between);
    // border: $border-size solid;
    padding: $padding*2;
    position: relative;

    $borderSpeed:.5s;
    $borderDelay:$borderSpeed + .5;
    margin-bottom: $padding;

    &:before, &:after {
      content: "";
      position: absolute;
      transition: all 1s $bezier;
      top:0;
      left:0;
      bottom: 0;
      right: 0;
      box-sizing: border-box;
      opacity: 0;
    }
    &:before {
      border-top: $border-size solid;
      border-right: $border-size solid;
      width: 0;
      height: 0;

      .scroll-point--active & {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity .1s $bezier $borderSpeed+$borderDelay, width $borderSpeed $bezier $borderSpeed+$borderDelay, height $borderSpeed $bezier $borderSpeed*2+$borderDelay;

      }
    }
    &:after {
      border-bottom: $border-size solid;
      border-left: $border-size solid;
      top: auto;
      left: auto;
      width: 0;
      height: 0;

      .scroll-point--active & {
        width: 100%;
        height: 100%;
        opacity: 1;
        transition: opacity .1s $bezier $borderSpeed*3+$borderDelay, width $borderSpeed $bezier $borderSpeed*3+$borderDelay, height $borderSpeed $bezier $borderSpeed*4+$borderDelay;

      }
    }
  }

  @include mqFrom($xl){
    margin-top: $margin;
    margin-bottom: $margin*2.2;
  }
}

ul.list, ol.list {
  // padding: 0;
  margin: 0 0 $margin-text;
  list-style:none;
  padding-left: $margin-text;

  p + .html-content & {
    margin-top: math.div($margin-text,-1.5);
  }

  li {
    margin-bottom: 0;
    line-height: inherit;

    &:before {
      content: "»";
      padding-right: .5rem;
      position: absolute;
      transform: translateX(-100%);
    }
  }
}

ol.list {
  counter-reset: ol-counter;

  li {
    counter-increment: ol-counter;  

    &:before {
      margin-left: 5px;
      content: counter(ol-counter) ".";
    }
  }
}

.preamble {
  // font-size: 2rem;
  // font-family: $font-serif;
  @include fontSerif();
}

p.quote, .quote > p {
  font-size: $font-size--larger;
  text-align: left;
  // font-family: $font-serif;
  @include fontSerif();
  position: relative;
  line-height: 1.15;

  &:before, &:after {
    padding: 0 .5rem;
    font-size: 2rem;
  }

  &:before {
    content: "»";
    position: absolute;
    transform: translateX(-100%);
  }
  &:after {
    content: "«";
  }

  @include mqFrom($landscape){
    font-size: $font-size--larger;
  }
  @include mqFrom($xl){
    font-size: $font-size--larger*1.4;
  }
}

.author {
  @include flex($align:flex-start, $dir:column);
  font-size: $font-size--mini;
  &__name {
    @include fontBold($size:$font-size--small);
  }

  &__title {
    // font-family: $font-serif;
    @include fontSerif();
    font-size: inherit;
    text-transform: none;
    // font-weight: normal;
  }

  &__corp {
    @include fontSerif();

    font-style: italic;
  }
}

p {
  margin-top:0;
  margin-bottom: $padding;
}

.header {
  @include flex($dir:column);
  text-align: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $margin;
  max-width: 100%;


      [class*="title"] {
        // color: $color-2023-purple;
        color: $color-2023-blue;
      }

  @include mqFrom($portrait){
    max-width: $max-width-header;

  }

  @include mqFrom($xl){


      max-width: 50vw;

  }

  p {
    font-family: $font-serif;
    @include fontSerif();

    margin-left: auto;
    margin-right: auto;

    @include mqFrom($portrait){

      .single-page-open & {
        font-size: 1.4rem;
        max-width: 80vw;

      }
    }
    @include mqFrom($desktop){
      font-size: 1.35rem;

      .single-page-open & {
        font-size: $font-size--large;
        max-width: 50vw;

      }
    }

  }

  * {
    @include mqFrom($portrait){
      max-width: $max-width-header;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.underline {
  // text-decoration: underline;
  text-decoration-color: $red;
  position: relative;

  // &:after {
  //   content: "";
  //   width: 100%;
  //   background: $red;
  //   height: $border-size;
  //   position: absolute;
  //   display: block;
  //   left: 0;
  // }
}

.inner {
  max-width: $max-width-inner;
  padding:$padding;

  @include mqFrom($xl){
    max-width: 85vw;
    padding:$padding*3 $padding;

  }
}

.bg- {
  &dark {
    background: $color-bg--dark;
    color: $white;
  }
  &blue {
    background: $blue;
    color: $white;
  }
  &beige {
    background: $beige;
  }
  &gray {
    background: $gray;
  }
  &light {
    background: $color-banner-bg;
    color: $color-lime;

  }
  &red-on-hover{
    transition: all 1s ease;
    &:hover {
      background: rgba($red,.6);
    }
  }
  &blue--light {
    // background: $blue--light;
    background: $color-blue--light;
    // color: $white;
    color: $color-blue--dark;
    *:before, *:after {
      border-color: $white;
    }
  }
  &gray--200 {
    // background: $blue--light;
    background: $color-2023-base--200;
    // color: $white;
    color: $black;
    *:before, *:after {
      border-color: $color-2023-gray--100;
    }

        .btn-circle--toggle {
          background: $color-lime;
          background: $color-2023-blue--light;
          color: $color-lime;
          color: $color-2023-blue--light;
          
          &:hover {
            background: $color-2023-blue;
            color: $black;
          }
        }
  }

  &image {
    // background: url(https://picsum.photos/id/399/1400/600?grayscale) no-repeat;
    background: url("../img/troax_mission.jpg") no-repeat;
    background-size: cover;
    color: $color-lime;
    color: $color-2023-blue;

    .btn-circle--toggle {
      background: $color-lime;
      background: $color-2023-blue;
      color: $color-lime;
      color: $color-2023-blue;
      color: $black;
      background: $black;
      
      &:hover {

            color: $black;
        background: $color-2023-blue;
      }
    }
  }
}

.color- {
  &lime, &-lime {
    color: $color-lime;
  }
  &purple, &-purple {
    color: $color-2023-purple;
  }
  &blue--light, &-blue--light {
    color: $color-2023-blue--light;
  }
}

.more-btn- {
  &blue--light, &-blue--light {
    [class*=btn-circle] {
      color: $color-2023-blue--light;
      background: $color-2023-blue--light;
    }
  }
}


.space- {

  @include mqFrom($portrait){

    &y {
      padding-top: $padding*6;
      padding-bottom: $padding;
    }
  }

  @include mqFrom($xl){

    &y {
      padding-top: $margin*4.8;
      // padding-bottom: $padding;
    }
  }
}

.relative {
  position: relative;
  .inner {
    z-index: 1;
  }
}


$t:1.3s;
$d:.3s;

.scroll-point {
  h1, p {
    transition: all $d*2 ease;
    opacity: 0;
    transform: translateY(50px);
  }


  &--active {
    h1, p {
      opacity: 1;
      transform: none;
      transition: opacity $t ease $d, transform $t ease ;

    }
    h1 {
      transition: opacity $t ease $d, transform $t ease ;
    }
    p {
      transition: opacity $t ease ($d + .1s), transform $t ease .1s;
    }
  }
}

.hidden {
  display: none !important;
}
.hide {
  opacity: 0 !important;
}

.no-padding {
  padding: 0;
}

.diagrams-numbers {

  header {
    max-width: 75vw;
  }
  .inner {
    padding-top: 0;
  }
  .numbers {
    max-width: 75vw
  }
}

#sales {
  .inner {
  padding-top: 0;
    transition: all 1s $bezier 0s;
    transition: all 1.2s ease-in-out 0s;
  }

  &.scroll-point--active {

    .inner {
      padding-top: 10vh;
    }
  }
}

.hidden-mobile {
  @include mqTo($portrait){
    display: none !important;
  }
}

