@import "../styles/variables.scss";

#map {
    path {
        transition-delay: 0s;
        animation: none;
        opacity: 1;
        transform: none;
    }
}

.map- {
    &color {
        fill: $color-blue;
        fill: $color-2023-blue--light;
    }
    &text-color {
        fill: #fff;
        transform: none;
        animation: none !important;
        opacity: 1 !important;
    display: none;
    }
}

.dot- {
    &sales {
        fill: $color-2023-purple;
    }

    &distribution {
        fill: #fff;
    }
    &production {
        fill: #000;
    }
}

[class*="dot-"]{
    display: none;
}
