@import './variables.scss';
@import './mixins.scss';

.sticky {
  transition: transform .3s ease-in-out;
  transform: translateY(0);
  display: block;
}

.sticky--stuck {
  position: fixed;
  top:auto;
  bottom:0;
  left: 0;
  right: 0;
  z-index: 200;

  @include mqFrom($portrait){
    bottom: auto;
    top:0;
  }
}

.sticky--hide {
  transform: translateY(100%);

  @include mqFrom($portrait){
    transform: translateY(-100%);
  }
}
