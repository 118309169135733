
@keyframes rotateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pieChart {
  0% {
    transform: scale(.95);
    opacity: 0;
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

$animationDelay: 0.25s;
$nthDelay: .25s;
$elements: 12;

[data-attr="lang-sv"]{
  #en, [id*="_en"], [data-name*=" en"] {
    display: none;
  }
}

[data-attr="lang-en"]{
  #sv, [id*="_sv"], [data-name*=" sv"] {
    display: none;
  }
}


article {
  svg:not(.blob) {

    > g:not([id]), > path:not([id]), > g[id] > * {
      opacity: 0;
      // animation: pieChart 1s 1 $bezier forwards;
      animation: pieChart .8s 1 ease-in-out forwards;
      transform-origin: center center;

      animation-delay: $animationDelay;

      .scroll-point & {
        animation-play-state: paused;
      }

      @for $i from 0 to $elements {
        $newDelay: $nthDelay*($elements - $i) + $animationDelay;

        &:nth-of-type(#{$i}) {
          animation-delay: $newDelay;
        }
      }

      .scroll-point--active & {
        animation-play-state: running;
      }
    }

  }

  &.scroll-point svg:not(.blob) {
    > g:not([id]), > path:not([id]), > g[id] > * {
      animation-play-state: paused;
    }
  }

  &.scroll-point--active svg:not(.blob) {
    > g:not([id]), > path:not([id]), > g[id] > * {
      animation-play-state: running;
    }
  }
}

#prefix__ {

  &circle, &profit, &sales {
    min-width: 300px;
    max-width: 450px;
    width: 25vw;
    overflow:visible;


    @include mqFrom($landscape){
      width: 30vw;

      height: 40vw;
      max-width: none;
      width: auto;

    }
  }

  // &cake1 {
  //
  //   [id*="cake"]{
  //     transform-origin: right bottom;
  //   }
  //
  //   &:hover {
  //     path:not([class]) {
  //       transform: scale(.98);
  //     }
  //   }
  // }

}

svg .prefix__ {

  // &st0{fill:#132A3E;}
  // &st1{fill:#FFFFFF;}
  // &st2{fill:#84A9BF;}
  // &st3{fill:#4B738C;}
  // &st4{fill:#DAD9D6;}
  // &st5{display:none;}
  // &st6{display:inline;}
  // &st7{fill:#5B5E63;}
}
