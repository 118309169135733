@use "sass:math";

@import './fonts.scss';
@import './variables.scss';
@import './mixins.scss';

.test {
  width: 1.8rem;
  height: 1rem;
  background: red;
  position: fixed;
  top: 39px;
  right: 158px;
  right: 308px;
}
