

.indent {
  text-indent: $padding;
}

span.indent {
  width: 1.5rem;
  display: inline-block;
  margin-top: 1.3rem;
}

.highlight-list {
  list-style: none;
  padding: $padding;
  background-color: #ffffff;
  margin-left: -$padding;
  // @include flex($alignY:flex-start);
  &:after {
    clear: both;
    content: "";
    display: block;
    width: 100%;
  }

  li {
      margin-bottom: math.div($padding,2);
  }

  @include mqFrom($portrait){

    li {
      width: 50%;
      float: left;
      clear: left;
      padding-right: math.div($padding,2);

      &:nth-child(even){
        float: right;
        clear: right;
        padding-left: math.div($padding,2);
        padding-right: 0;
      }
    }
  }

  &__title {
    display: block;
    line-height: 1.1;
    margin-bottom: .3rem;
  }

  
}

article {
  width: 100%;
  @include flex($alignY:flex-start);
  padding-top: $margin;

  &#mission > .inner {
    padding: 0;

    h1 {
      line-height: .8;
    }
  }

  &#group > .inner {
    padding: $padding*1.9 1rem;

    @include mqFrom($portrait){
      padding: $padding*1.9;
    }

    h1 {
      line-height: .8;
    }
  }

  .parallax {
    z-index: -1;
  }

  p +, .html-content + {
    h2,h3 {
      margin-bottom: math.div($margin-text,4);

      + h2, + h3 {
        margin-top: math.div($margin-text,2);
      }
    }
  }

  h2 +, h3 + {
    h2,h3 {
      margin-bottom: math.div($margin-text,4);
    }
  }

  [loading="true"] & {
    opacity: 0;
    transform: translateY(0px);
    transition: all .3s $bezier;
  }

  [loading="false"] & {
    transform: none;
    transition: all .6s $bezier .1s;

    .single-page-open & {
      opacity: 1;
    }
  }

  .inner {
    @include flex($alignY:flex-start);
    text-align: center;
    width: 100%;

    h1,h2,h3,p {
      width: 100%;
    }

    .diagram {
      margin: auto $padding;

      @include mqFrom($desktop){
        margin: auto $padding*1.5;
      }

      + .diagram {
        margin-bottom: 25%;
      }
    }
  }
}

.diagram {


  .parallax & {
    padding: 5vh 0 0;

    @include mqFrom($portrait){
      padding: 20vh 0 0;
    }

  }
}

.single-article {
  backface-visibility: hidden;

  .pdf {
    position: fixed;
    top:3vh;
    right: 3vh;
  }

  p a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;
  }

  .btn-circle--close {
    position: fixed;
    top:3vh;
    left:3vh;
    opacity: 0;
    transition: opacity 1s ease 0s;

    .single-page-open & {
      opacity: 1;
      transition: opacity 1s ease 2s;

    }

  }

  .scrollable {
    max-width: 50vw;

    &.img-portrait {
      max-width: 30vw;
    }
  }

  .cooperation {
    .scrollable {
      &.img-portrait {
        @include mqTo($portrait){
          max-width: 38vw;
        }
      }
    }
  }
  

  article {
    @include flex($dir:column, $align:flex-start);
    padding: $padding*4 0 $padding;
    width: 100%;
    max-width: $max-width-article;

    .text-content {
      max-width: 80vw;
      margin-left: auto;
      margin-right: auto;
      @include flex($dir:column, $align:flex-start);

      > .scrollable:first-child {

      }

      > img {
        margin-bottom: $margin-text;
      }

      > p {
        // text-indent: $padding;

        &.indent {
          margin-bottom: .3rem;

          + h1, + h2, + h3, + h4, + h5, + figure, + img, + ul, + ol, + .parallax {
            margin-top: $margin-text;

          }

        }


      }

      @include mqFrom($portrait){
        max-width: $max-width-text;

      }
    }

    > p, > ul, > ol {
      // max-width: 80vw;
      // margin-left: auto;
      // margin-right: auto;
      //
      // @include mqFrom($portrait){
      //   max-width: $max-width-text;
      //
      // }
    }

    > * {
      order:1;
      margin-bottom: $margin-text;
    }
    h1 {
      order: -1;
    }


  }

  .columns {
    columns: 2;
    column-gap: $padding;
  }
}

.article-footer {
  @include flex($alignX:flex-end);
  width: 100%;
  border-top:1px solid $beige;
  padding: math.div($padding,2) $padding $padding;
  margin: 0 $padding;
  margin-top: $margin;
  margin-top: 1rem;

  .bg-beige & {
    border-color:$color-text;
  }

  .bg-blue--light & {
    border-color:#fff;
  }

  .inner {
    @include flex($alignX:flex-end);
    margin: auto;
    max-width: $max-width-text;
    padding: 0 $padding $padding;

  }

  @include mqFrom($xl){
    padding: math.div($padding,2) $padding $padding*2.1;

  }
}

.align- {
  &right {
    align-self: flex-end;
  }
  &left {
    align-self: flex-start;
  }
  &center {
    align-self: center;
  }
}
