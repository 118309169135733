@use "sass:math";

//Breakpoints
$portrait:768px;
$landscape:1024px;
$desktop:1100px;
$xl:1350px;
$xxl:2050px;


// Colors general
$blue:#4c748d;
$blue--light:#83a9bf;
$blue--dark:#152b3f;
$brown: #8c8179;
$beige: #b4aca5;
$black: #222222;
$gray: #d9dad6;
$red: #f45932;
$white: #f7f7f7;

$troax-global-blue: #9db9d5;

// Color variables
$color-primary: rgba(150,150,250,1);
$color-secondary: green;
// $color-bg: #f7f7f7;
$color-bg: #f7f7f7;
$color-bg--dark: $blue--dark;
$color-bg--menu: rgba(#fff,1);
$color-bg--article: $blue--dark;
$color-bg--article: rgba($beige,.7);
$color-bg--article: url("/img/noise.png"), rgba($beige,.7) ;
$color-bg--article: url("/img/noise.png"), rgba($white,.75) ;
$color-bg--article: url("/img/noise.png"), rgba($white,1) ;
// $color-bg--article: linear-gradient(125deg, $beige,$white);
$color-text: $black;
$color-link: rgba(150,150,250,1);
$color-link--menu: #fff;


// 2021
$color-banner-bg: #eeebe9;
$color-gray: #e5e0dc;
$color-lime: #deed84;
$color-blue--light: #c1d1db;
$color-blue: #7e94ac;
$color-blue--medium: #596876;
$color-blue--dark: #18293c;

// 2022
$color-2022-purple: #c4badc;
$color-2022-purple--light: #e0ddec;
$color-2022-gray--400: #5b5d62;
$color-2022-gray--300: #adaeb0;
$color-2022-gray--200: #dad9d6;
$color-2022-gray--100: #f6f5f5;
$color-banner-bg: $color-2022-gray--200;
$color-link--menu: $color-2022-purple;





// 2023
$color-2023-base--100: #f8f6f7;
$color-2023-base--200: #eae6e1;
$color-2023-beige: #dbcca8;
$color-2023-blue: #1c2cc5;
$color-2023-blue--light: #9db9d5;
$color-2023-blue--dark: #112360;
$color-2023-green: #daeab2;
$color-2023-gold: #c0b160;
$color-2023-purple: #7e335b;
$color-2023-purple--light: #b86792;
$color-2023-gray--400: #5b5d62;
$color-2023-gray--300: #adaeb0;
$color-2023-gray--200: #dad9d6;
$color-2023-gray--100: #f6f5f5;
$color-banner-bg: $color-2023-gray--200;
$color-link--menu: $color-2023-purple;
$color-bg: $color-2023-base--100;

// Font styles
$font-size:16px;
$font-normal: "Avenir", sans-serif;
$font-normal: 'Avenir LT W01_45 Book1475508', sans-serif;
// $font-normal: "MinionPro", sans-serif;
$font-serif: "GaraBol", serif;
$font-serif: "adobe-garamond-pro", serif;
// $font-serif: "Garamond Pro", fantasy;
$font-heavy: 'Avenir LT W01_85 Heavy1475544', sans-serif;
$font-bold: 'Avenir LT W01_95 Black1475556', sans-serif;
$font-title: "Alternate Gothic W05 No_1",serif;
$font-number: "Avenir LT W01_35 Light1475496", serif;

// $font-title: "Avenir", sans-serif;
// $font-title: "DIN Alternate", serif;
$line-height:1.5;
$line-height-heading:0.85;
// $font-size--mini:.75rem;
// $font-size--mini:.85rem;
// $font-size--small:.9rem;
// $font-size--normal:1rem;
// $font-size--large:1.5rem;
// $font-size--larger:1.7rem;
$font-size--mini:.85rem;
$font-size--mini:.9rem;
$font-size--small:.95rem;
$font-size--normal:1rem;
$font-size--large:1.9rem;
$font-size--larger:1.8rem;


// Transitions
$bezier: cubic-bezier(.51,.14,.29,1);


// Distances
$gutter:3rem;
$padding: math.div($gutter,2);
$margin: $gutter;
$margin-text: math.div($gutter,2);


// Buttons
$btnToggleSize:2.7rem;
$btnColor:rgba(255,255,255,1);
$btnColor:$blue--dark;
$btnColor:$black;
$btnColorHover:rgba(255,255,255,0.3);
$btnColorHover:rgba($blue--dark,0.85);
$btnColorHover:rgba($color-2023-blue,0.85);


// Max widths
$max-width:1300px;
$max-width-inner:1100px;
$max-width-text:650px;
$max-width-header:65vw;
$max-width-article:750px;

// General Sizes
$border-size:4px;
$line-size:1px;
