@use "sass:math";

@import './variables.scss';
@import './mixins.scss';

.units {
  align-items: flex-start;
  // flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  // width: 100%;
      justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: auto;
  white-space: nowrap;
  padding-right: 100px;
  
  @include mqFrom($xxl) {
      padding-right: 0px;
      justify-content: center;
    }

  &::-webkit-scrollbar {
    display: none;
  }
}

$nthDelay: .1s;
$elements:8;
$animationDelay:.5s;

.unit {
  // background: #e3e3e3;
  padding: $padding math.div($padding,2);
  @include flex($dir:column,$align:flex-start);
  min-width: 150px;
  max-width: 250px;
  width: 50%;
  text-align: left;
  opacity: 0;
  transform: translateY(30px);
  transition: all 1s ease;
  white-space: initial;
  flex:none;

  .scroll-point--active & {
    opacity: 1;
    transform: none;
  }

  @for $i from 0 to $elements {
    &:nth-of-type(#{$i}) {
      .scroll-point--active & {
        $newDelay: $nthDelay* $i + $animationDelay;
        transition-delay: $newDelay;
      }
    }
  }

  @include mqFrom($portrait){
    padding: $padding;
    width: 170px;
    width: calc(100%/6);
    // margin-bottom: -10%;
    z-index: 1;
  }

  .country {
    text-transform: uppercase;
    // color: $blue--dark;
    color: $white;
    color: $black;
    @include fontTitle($size:2rem);

    @include mqFrom($desktop){
      font-size: 2.2rem;
    }

  }

  .city {
    font-size: $font-size--small;
  }

  .desc, .highlight-desc {
    font-size: $font-size--mini;
    line-height: 1.4;
  }

  .desc {
    height: 5rem;
    max-width: 150px;

    [data-attr="lang-en"] & {
      height: 5rem;
    }

    @include mqFrom($desktop){
      // padding-right: .5rem;
    }
  }

  .numbers {
    padding: $margin-text 0;
    margin: 0;
    @include flex($dir:column,$align:flex-start);

    li {
      @include flex($dir:column,$align:flex-start);
      width: 100%;
    }

    .highlight {
      @include fontTitle();
      margin-top: .2rem;
    }
  }

  .extra {
    @include fontTitle($size: $font-size--normal);
    letter-spacing: .5px;
  }
}

#units {
  [class*="title"] {
    color: $black;
  }
  .article-footer {
    margin-top: -7%;
  }
}

.production-units-map {
  width: 100%;

  img {
    width: 100%;
  }

  .scroll-point--active & {
    // transform: translateY(-20%);
    // transition: all 1.6s $bezier 1s;
  }
}


@function url-friendly-colour($colour) {
  @return '%23'+str-slice('#{$colour}', 2, -1);
}

@keyframes scrollArrow {
  0%,20%,40%,60%,100% {
    background-position: 50% 35%;
  }
  10%,30%,50% {
    background-position: 60% 35%;
  }
}

.units-wrapper {
  overflow: visible;
  width: 100vw;
  position: relative;
  padding-bottom: 10%;;

  // &:after {
  //   content: '';
  //   position: absolute;
  //   right: 0;
  //   top:0;
  //   bottom:0;
  //   width: 200px;
  //   background: url('data:image/svg+xml,<svg width="22" height="18" viewBox="0 0 22 18" fill="#{url-friendly-colour($color-2023-blue--dark)}"  xmlns="http://www.w3.org/2000/svg" ><path d="M0 9L20 9" strokeWidth="0" /><path d="M12.04 1.28571L19.72 8.99999L12.04 16.7143" strokeWidth="0" /></svg>') no-repeat center 25%, linear-gradient(90deg, transparent, $color-2023-base--200 75%);

  //   animation: scrollArrow 5s infinite;
  //   z-index: 10;

    
  // }

  //   @include mqFrom($xxl) {
     
     
  //   &:after {
  //       display: none;
  //    }
  //   }

  .scroll-point--active & {
      transition: all 2s ease 1s;
      // margin-bottom: -12%;
      margin-bottom: -22%;

    }
}

.units-more {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100px;
  background: url('data:image/svg+xml,<svg width="22" height="18" viewBox="0 0 22 18" fill="#{url-friendly-colour($color-2023-blue--dark)}"  xmlns="http://www.w3.org/2000/svg" ><path d="M0 9L20 9" strokeWidth="0" /><path d="M12.04 1.28571L19.72 8.99999L12.04 16.7143" strokeWidth="0" /></svg>') no-repeat center 25%,
  linear-gradient(90deg, transparent, $color-2023-base--200 75%);
  animation: scrollArrow 5s infinite;
  z-index: 10;
  
  @include mqFrom($portrait) {
    width: 200px;
  }

  @include mqFrom($xxl) {

    &:after {
      display: none;
    }
  }
}