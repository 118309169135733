@import './mixins.scss';
@import './variables.scss';

.video-wrapper {
  width: 100%;
  position: relative;
  // display: none;

  video,img, iframe {
    width: 100%;
    max-height: 30vh;
    object-fit: cover;

    @include mqFrom($portrait){
      max-height: 100vh;

    }
  }

  .controls {
    padding: $padding;
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right:0;
    @include flex();
    margin: auto;
    border-radius: 50%;
  }
}



.play-btn {

  position: absolute;
  top:0;
  bottom:0;
  left: 0;
  right:0;
  width: 150px;
  height: 150px;
  margin: auto;
  transition: all .6s $bezier;

  @include mqFrom($portrait){
    width: 200px;
    height: 200px;
  }

  .playing & {
    opacity: 0;
    transform: scale(.8);
    pointer-events: none;
  }

  i {
    width: 20%;
    height: 20%;
    margin-left: -4%;

    @include mqFrom($portrait){
      width: 35%;
      height: 35%;
      margin-left: -4%;
    }
  }

  svg {
    fill:#fff;
    width: 100%;
    height: 100%;
  }

  &__bg {
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right:0;
    height: 100%;
    object-fit: contain;
    background: none !important;
    transition: all .1s linear;
    // animation: rotateCircle 6s infinite linear forwards;
    // animation-play-state: paused;
  }

  &:hover {
    background: none !important;
    .play-btn__bg {
      transform: scale(1.1) !important;
      transition: all .6s $bezier;

      // animation-play-state: running;
    }
  }
}
