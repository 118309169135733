@import './variables.scss';
@import './mixins.scss';

.parallax {
  transition: all .2s linear;
  -webkit-transition: all .1s linear ;
  position: relative;
  margin-bottom: $margin-text;
-webkit-backface-visibility: hidden;
transition:none;
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  will-change: transform;

  @include mqTo($portrait){
    transform: none !important;
  }
}

.parallax--fade {
  opacity: 0;
}

.-img {
  &-top {

      @include mqFrom($portrait) {
        margin-top: -70%;
      }
  }
}


.img {

  &-logo {
    width: 30vw;

    + [class*="img-left"]:last-of-type {
      // margin-top: -15%;

      @include mqFrom($portrait){
        margin-bottom: -15%;
      }
    }
  }

  &-smaller {
    max-width: 40vw !important;
  }

  @include mqFrom($portrait){

    &-left {
      margin-left: -50%;
      align-self: flex-start;

      + [class*="img-right"] {
        margin-top: -75%;
        align-self: flex-end;
      }
    }
    &-right {
      margin-right: -50%;
      align-self: flex-end;

      + [class*="img-right"] {
        margin-top: 0%;

      }

    }

    &-left--slight {
      margin-left: -25%;
      align-self: flex-start;

      + [class*="img-right"] {
        margin-top: -50%;
        align-self: flex-end;
      }
    }
    &-right--slight {
      margin-right: -25%;
      align-self: flex-end;

      + [class*="img-left"] {
        margin-top: -50%;

      }

    }
  }
}
