.menu {
  @include flex($alignX:space-between);
  padding:$padding;
  background: $color-bg--menu;
  transition: all .5s ease;

  @include mqFrom($portrait){
    background: none;

    padding:$padding $margin;
  }

  @include mqTo($portrait){
    a.pdf {
      margin-right: auto;
    }
  }

  .annual-as-text {
    width: 200px;
    width: 120px;
    height: 42px;
    align-self: center;
    margin: auto auto auto .5rem;
    // margin-right: 0;
    display: none;
  }

  .pdf-as-text {
    width: 90px;
    height: 42px;
    // margin: auto 1.5rem;
    margin: auto 1rem;
    // margin: auto 0;
    background: url("../img/troax-pdf-svg.svg") no-repeat;
    background: url("../img/troax-download-annual-report-2020.svg") no-repeat;
    background-size: contain;
    background-position: right;

    [data-attr="lang-sv"] & {
      background: url("../img/troax-download-annual-report-2020_sv_.svg") no-repeat;
      background-size: contain;
      background-position: right;

      .menu-is-open & {
        background: url("../img/troax-download-annual-report-2020-white_sv_.svg") no-repeat;

      }
    }

    .menu-is-open & {
      background: url("../img/troax-pdf-svg-white.svg") no-repeat;
      background: url("../img/troax-download-annual-report-2020-white.svg") no-repeat;
      z-index: 20;
    }
  }

  &:hover {
    background: rgba(#fff,.8);
    // background: $color-bg--menu;


    * {
      color:$black !important;
      fill:$black !important;
    }

  }
  &-toggle {
    z-index: 100;
    @include flex();
    cursor: pointer;

    i {
      @include flex();

      svg {
        color: $blue--dark;
      }
    }

    @include mqFrom($portrait){
      // display: none;
      margin-left: 2rem;
    }
  }

  svg {
    transition: all .3s ease .6s;
  }



  nav {
    @include flex($dir:column, $align:center);
    text-align: left;
    flex:1;
    align-self: flex-start;
    position: fixed;
    // top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: $blue--dark;
    background: $black;
    background: $color-blue--light;
    background: $color-2023-blue--light;
    // padding-top: 15vh;
    opacity: 0;
    pointer-events: none;
    transition: all .6s $bezier;
    transform: scale(1.5);

    .desc {
      @include flex($dir:column,$align:flex-start);
      // position: absolute;
      // bottom: 0;
      // left: 0;
      position: relative;
      // color: red;
      //height: 100px;
      width: 200px;

      > h4, img, .troax-group {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: rotate(-90deg) translateX(-1rem);
        white-space: nowrap;
        margin: 0;
        margin-left: -$margin;
        margin-left: math.div($padding,-2);
        transform-origin: left;
        max-width: 113px;
        max-height: 42px;
      }

      * {
        @include flex($dir:column, $align:flex-start);

        font-size: $font-size--mini;
      }
    }

    img {
      max-width: 35vw;
      margin:$margin ;

      @media screen and (orientation:portrait){
        margin-left: 0;
      }
    }

    @include mqFrom($portrait){
      @include flex($dir:column,$alignX:flex-start);
      // position: relative;
      // height: auto;
      // background: none;
      // padding: 0;
      // align-self: center;
      // opacity: 1;
      // pointer-events: all;
      // transform: none;
      padding: $margin $margin *2.5;
      bottom: auto;
      top:0;
      transition: all .6s $bezier .3s;
      transition: all .6s ease .4s;

    }


  }

  a {
    display: block;
    padding: .2rem 1rem;
    text-decoration: none;
    color: $color-link--menu;
    @include fontTitle;
    font-size: 1.6rem;
    
    .light-menu & {
      color:#fff;
    }

    &:hover {
      text-decoration: underline;
    }

    @include mqFrom($portrait){

      padding: .2rem 1rem;
      line-height: 1;
      @include fontTitle;
      font-size: 3rem;


    }
    @include mqFrom($landscape){
      // font-size: 2.5rem;
    }
    @include mqFrom($desktop){
      font-size: 3.5rem;
    }
  }

  .logo {
    width: 100px;
    z-index: 100;
    padding: 0;
    margin: auto auto auto 0;

    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    @include flex();

    @include mqFrom($portrait){
      width: 90px;
      position: relative;
      margin: auto auto auto 0;
      margin: auto 0 auto 0;

    }

    svg {
      width: 100%;
      height: auto;

      * {
        fill:$black;

      }
    }
  }

  .light-menu & {
    .btn-circle {
        color: #fff;
      }
    
      svg {
        * {
          fill: #fff;
        }
      }
  }

  &.menu--open {
    nav {
      opacity: 1;
      pointer-events: all;
      transform: none;
    }

    .btn-circle {
      color:#fff;
    }
    svg {
      * {
        fill:#fff;
      }
    }

  }
}

.nav-background {
  background: $beige;
  background: rgba($beige,.85);
  background: rgba($beige,0);
  background: rgba(#fff,.8);
  top:0;
  width: 100%;
  position: sticky;
  z-index: 100;
  position: fixed;
  transition: all 1.3s ease;
  display: none;
  pointer-events: none;
  .sticky--hide + & {
    opacity: 0;
    // filter: blur(100px);
    // transform: scale(2);
  }

  @include mqFrom($portrait){
    // display: block;
  }
}

.troax-group {
  @include flex($dir:column, $alignX: flex-start);
  line-height: 1.1;
  // position: absolute;
  // left: 155px;
  font-size: .8rem;
  letter-spacing: -0.01rem;
  margin: auto auto auto 1rem;

  &__text {
      font-family: $font-number;
  }
  &__year {
      font-family: $font-bold;
  }

  .light-menu & {
    color: #fff;
  }

  .menu-is-open .desc & {
    color: #fff;
    @include flex($dir:column, $alignX: flex-start, $alignY: center);
    width: 101px;
    height: 42px;
    // margin: 0;
  }
}

.troax-download {
  @include flex($dir:column, $alignX: flex-start);
  line-height: 1.1;
  // position: absolute;
  // right: 180px;
  // top: 27px;
  font-size: .75rem;
  letter-spacing: -0.03rem;
  margin: auto auto auto 0.5rem;
  z-index: 10;
  width: 90px;
  height: 42px;
  margin: auto 1rem;



  .menu-is-open &,.light-menu & {
    color: #fff;
  }

  &__top {
      font-family: $font-number;
  }
  &__text {
      font-family: $font-bold;
  }
  &__year {
      font-family: $font-bold;
  }
}