

*:not(article)[class*="btn"]{
  transition: all .3s $bezier;
  cursor: pointer;
  @include flex();
  color: $color-text;

  &:hover {
    transition: all .3s ease;
    background: $btnColorHover;
    svg {
      fill: white !important;
    }
  }
}

[class*="btn-circle"] {
  @include circleBtn;
  cursor: pointer;

  svg {
    fill: $white;
    outline: none;

  }

  .bg-dark & {
    background: $white;
    color: $white;

    &:hover {
      background: rgba($white,.85);
    }

    svg {
      fill: $color-bg--dark;
      fill: $color-2023-gray--400;
    }
  }
}

@keyframes animateBtn {
  0%, 100% {
    transform: none;
  }
  25% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1.1);
  }
}

.btn-circle--toggle {
  outline: none;

  i,svg {
    outline: none;
  }

  &.animate {
    transform: translateX(500%);
    animation: animateBtn .6s 1 $bezier forwards;
  }

  &:before {
    content: "Read More";
    transform: translateX(-115%);
    position: absolute;
    line-height: 1.2;
    text-transform: uppercase;
    font-family: $font-heavy;
    font-weight: 600;
    padding-top: .2rem;
    transition: all .3s ease;

    [data-attr="lang-sv"] & {
      content: "Läs mer"
    }
  }

  .bg-blue--light & {
    background: #fff;
    color: #fff;

    i svg {
      fill:$blue--dark;
      fill: $color-2023-gray--400;
    }
  }

  &:hover {
    &:before {

    }
  }
}

.lang-sel {
  position: relative;
  margin-right: $padding;

  &:after {
    content: "";
    position: absolute;
    transform: translateX($padding*1.95);
    transform: translateX($padding*1.35);
    width: 2px;
    height: 3rem;
    background: $color-text;

    @include mqFrom($portrait){
      transform: translateX($padding*2.5);

    }

    .menu-is-open &, .light-menu .menu:not(:hover) & {
      background: #fff;
    }
  }

  &:before {
    content: "Language";
    font-size: .7rem;
    transform: translateX(-100%);
    height: .7rem;
    margin: auto ;
    position: absolute;
    top:0;
    bottom: 0;
    margin-left: -$padding;
    text-transform: none;
    font-family: $font-normal;
    display: none;
    
    [data-attr="lang-sv"] & {
      content: "Språk";
      transform: translateX(-130%);
    }

    @include mqFrom($portrait){
      display: block;
    }
  }

  @include mqFrom($portrait){
    margin-right: $padding*1.5;
    margin-right: $padding*2.5;
  }
}

.pdf, .lang-sel {

  background: none;
  color: #fff;
  // color: $blue--dark;
  border: 3px solid ;

  font-family: $font-bold !important;
  font-size: inherit !important;
  padding: 0 !important;
  display:flex !important;
  text-decoration: none !important;

  + .pdf, + .lang-sel {
    margin-left: .5rem;
  }

  span {
    font-size: .8rem;

  }

  > img {
    width: 83%;
    filter: saturate(30%);
    border-radius: 50%;
  }

  img + span {
    display: none;
  }

  i svg {
    fill: $blue--dark;
    fill: #fff;

    display: none;
  }

  .menu &, .single-article & {
    color: $blue--dark;
    color: $black;

    i svg {
      fill: $blue--dark;
      fill: $black;
    }
  }

  &:hover span{
    color: #fff;
  }

  @include mqFrom($portrait){
    position: relative;
  }

}
