@mixin flex($display: flex, $dir: row, $align: center, $alignX: $align, $alignY: $align, $wrap:wrap) {
  display: $display;
  flex-wrap: $wrap;
  flex-direction: $dir;

  @if $dir == "row" {
    align-items: $alignY;
    justify-content: $alignX;
  } @else {
    justify-content: $alignY;
    align-items: $alignX;
  }
}

@mixin mqFrom($media: $landscape) {
  @media screen and (min-width: #{$media}) { @content; }
}
@mixin mqTo($media: $landscape) {
  @media screen and (max-width: #{$media}) { @content; }
}

@mixin circle($color:$color-text, $border:0,$borderColor:$color, $background:$btnColor, $radius:7rem) {
  border-radius: 50%;
  width: $radius;
  height: $radius;
  background: $background;
  color: $color;

  border:$border solid $borderColor;
  @include flex($dir:column, $wrap:nowrap);
  position: relative;
}

@mixin circleBtn($color:$color-text, $border:0, $background:$btnColor, $radius:$btnToggleSize) {
  @include circle($color:$color-text, $border:0, $background:$btnColor, $radius:$btnToggleSize);

  font-size: .8rem;
  @content;
  cursor: pointer;

  @if $border == 0 {
    // box-shadow: 0 2px 8px -5px $color;
  }

  i {
    margin: 0;
    font-size: 1.4rem;
    line-height: .5;
    transition: transform .5s $bezier .2s;
    transform-origin: center;

    &:only-child {
      position: absolute;
      top:0;
      bottom: 0;
      left: 0;
      right: 0;
      @include flex();

    }

    svg {
      margin: 0;
      backface-visibility: hidden;
    }
  }

  span {
    font-size: .5rem;

    + i {
      font-size: .8rem;

      svg {
        height: .8rem;
      }
    }
  }

  &:hover {
    // transition: all .3s ease;
    // background: $btnColorHover;
    // transform: scale(.95);


    i:only-child {
      transform: rotate(180deg);
      transition: transform .5s $bezier .2s;
    }
  }
}

@mixin fontTitle($size:$font-size--large, $weight:normal,$text:uppercase){
  font-size: $size;
  font-family: $font-title;
  font-weight: $weight;
  text-transform: $text;
  letter-spacing: -0.025em;
}

@mixin fontBold($size:$font-size--large, $weight:900,$text:uppercase){
  font-family: $font-bold;
  font-weight: $weight;
  text-transform: $text;
}

@mixin fontSerif(){
  font-family: $font-serif;
  font-weight: 400;
  font-style: normal;
}
