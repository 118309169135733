@use "sass:math";


@import './base.scss';
@import './numbers.scss';


@keyframes highlightText {
  0%,100% {
    transform: none;
  }
  25%,50% {
    transform: translateY(5px);
  }
}

@keyframes pulse {
  0%,100% {
    transform: translate(-65%, 61%);
  }
  50%,75% {
    transform: scale(1.1) translate(-65%, 61%);
  }
}

@keyframes plus-pulse-w {
  0%,100% {
    width: 100%;
  }
  50%,75% {
    width: 120%;
    margin-left: -10%;
  }
}

@keyframes plus-pulse-h {
  0%,100% {
    height: 100%;
  }
  50%,75% {
    height: 120%;
    margin-top: -10%;
  }
}

.pulse {
  transform-origin: center;
  animation: pulse 1s infinite;
}


$elements:8;
$animationDelay:.5s;

#brief {
  header{
    // [class*="title"]{
    //   color: $color-2023-blue;
    // }

    * {
      opacity: 1 !important
    }
  }
}


.brief {
  width: calc(100%/2);
  padding: 1rem;
  opacity: 0;
  transform: translateY(30px);
  transition: all 1s ease;

  font-family: $font-number;
  font-weight: 100;

  @include mqFrom($portrait){
    width: calc(100%/3);
    padding: 0;
  }

  .scroll-point--active & {
    opacity: 1;
    transform: none;
  }
  &:hover {
    .highlight {
      color:$blue--dark;
      transform: translateY(-5px) scale(1.1);
      // text-shadow: 0 4px 4px rgba($black,.3);

      ~ * {
        transform: translateY(5px);
      }
    }
  }

  @for $i from 0 to $elements {
    &:nth-of-type(#{$i}) {
      $newDelay: $nthDelay* $i + $animationDelay;

      .scroll-point--active & {
        transition-delay: $newDelay;
      }

      .highlight {
        line-height: 1.3;

        .highlight & {
          animation-delay: 10 * $newDelay;
          animation-delay: 5 * $newDelay;

          ~ * {
            animation-delay: 5 * $newDelay;
          }
        }
      }
    }
  }

  .highlight {
    line-height: 1.3;
    transition: all .3s $bezier;
    text-shadow: 0 0px 0px rgba($black,.3);

    ~ * {
      transition: all .5s ease;
    }

    .highlight & {
      animation: highlight 1.2s 1 0.5s;
      // animation: highlight 2s infinite 2.5s;
      ~ * {
        animation: highlightText 1.2s 1 0.5s;

      }

    }
  }

  .title {
    // font-size: .8rem;
    @include fontBold();
    font-size: $font-size--mini;
  }

  .end {
    font-size: .9rem;
    margin-top: .3rem;

    b {
      font-family: $font-bold;
    }
  }

}

.brief-extra {
  position: relative;
  padding: $padding*3 $padding;

  @include mqFrom($landscape){
    padding: $padding*6 $padding;

  }

  .images-wrapper {
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    @include flex();

    @include mqFrom($landscape){
    position: relative;
    }

    img {
      max-width: 55vw;
      padding: 2vw;
      margin-right: -112vw;
      margin-left: auto;
      margin-top: -125vw;

      &:last-child {
        max-width: 50vw;
        margin-left: auto;
        margin-right: -12vw;
        margin-bottom: -195vw;

      }

      @include mqFrom($landscape){
        max-width: 45vw;
        padding: 2vw;
        margin-left: -65vw;
        margin-top: 0;

        &:last-child {
          max-width: 30vw;
          margin-right: -60vw;
          margin-bottom: -27vw;

        }
      }
    }
  }
  .parallax {
    z-index: 0;
  }
}

.hotspots {
  z-index: 1;
  width: 100%;
  @include mqFrom($portrait){
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
  }
}

@keyframes spin-plus {
  0% {
    transform: none;
  }
  25% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-180deg);

  }
}

.plus {
  width: 100px;
  height: 100px;
  position: relative;
  @include flex();

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    content: "";
    background: $color-2023-blue;
    transition: transform .6s ease;
    will-change: transform;

  }
  
  &:before {
    width: 100%;
    height: 2px;
    margin: auto 0;
    animation: plus-pulse-w 2s infinite;
  }
  
  &:after {
    height: 100%;
    width: 2px;
    margin: 0 auto;
    animation: plus-pulse-h 2s infinite;
  }
}

.hotspots {
  height: 100vh;

  @include mqFrom($portrait) {
    height: auto;
  }
}

.hotspot {
  max-width: 100%;
  margin-left: 3.5rem;
    position: absolute;
  @include mqTo($portrait){
    &:nth-child(odd){
      // margin-left: 35%;
      // margin-bottom: 6rem;
    position: absolute;

    }
  }

  @include mqFrom($portrait){
    position: absolute;
    max-width: 27vw;
    margin: 0;
  }

  .plus {
    width: 100px;
    height: 100px;
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-65%, 61%);
    // animation: pulse 2s infinite;

  }

  // &:not(.active){
  //   .plus {
  //     &:before, &:after {
  //       animation: pulse 1s infinite;
  //     }
  //   }
  // }

  &.active {
    .plus {
      &:before, &:after {
        transform: rotate(45deg);
        // background: $black;
        // animation-play-state: paused;
        animation:none;
      }
    }
  }

  .additional {
    padding: $padding;
    background: $color-2023-blue;
    color: $white;
    position: relative;
    z-index: 1;
    // display: none;
    transform: translate(-17px, -20px) scale(.8);
    opacity: 0;
    transition: all .3s $bezier;
    height: 0;
    pointer-events: none;


    &.active {
      display: block;
      height: auto;
      opacity: 1;
      transform: translate(-17px, -20px) scale(1);

    }
  }
}

.spot- {

  &head {
    position: relative;
    color: $black;
    transition: all .3s ease;
    cursor: pointer;
    filter: drop-shadow(0px 0px 55px rgba(#f7f7f7,.75 ));
    -webkit-filter: drop-shadow(0px 0px 55px rgba(#f7f7f7,.75 ));
  //   text-shadow:
  //  -1px -1px 0 rgba(#f7f7f7,.1),  
  //   1px -1px 0 rgba(#f7f7f7,.1),
  //   -1px 1px 0 rgba(#f7f7f7,.1),
  //    1px 1px 0 rgba(#f7f7f7,.1);

    [class*="title"]{
      // font-size: 3.6rem;
      z-index: 1;
      position: relative;
      @include mqFrom($landscape){
        font-size: 3.6rem;

      }
    }

    &:hover, .active & {
      color: $color-2023-blue;
    }
  }

     &0 {
       left: 5%;
       top: 2%;
     }
  
     &1 {
       left: 5%;
       top: 20%;
     }
  
     &2 {
       left: 5%;
       top: 38%;
     }
  
     &3 {
       left: 5%;
       top: 54%;
     }
  
     &4 {
       left: 5%;
       top: 72%;
     }
  
     &5 {
       left: 5%;
       top: 90%;
     }
  

  @include mqFrom($portrait){
    &0 {
      left: 25%;
      top: 7%;
    }
    &1 {
      right: 10%;
      left: auto;
      top: 10%;
    }
    &2 {
      left: 40%;
      top: 35%;
    }
    &3 {
      left: 47%;
      top: 65%;
    }
    &4 {
      left: 65%;
      top: 40%;
    }
    &5 {
      left: 65%;
      top: 75%;
    }
  }
}
