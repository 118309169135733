@use "sass:math";

@import './variables.scss';
@import './mixins.scss';

.images {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "\31  \32 " "\33  \34 ";

  grid-column-gap: $padding;
  grid-row-gap: $padding;

  @include flex($alignX:flex-start);
  display: block;

  @include mqFrom($landscape){
    margin: $margin auto 0;
  }

  figure {
    margin:0;
    width: 50%;
    padding: math.div($padding,4);
    float: left;

    @include mqFrom($portrait){
      padding: $padding;
    }

    // @include flex();
    // overflow: hidden;
    //
    // img {
    //   width: 150%;
    //   object-fit: none;
    // }

    &.max-80 {
      text-align: right;

      img {
        max-width: 82%;
      }
    }
    &.max-90 {
      text-align: left;

      img {
        max-width: 85%;
      }
    }

    &:nth-child(2){
      float: right;
    }
  }

  img {
    // grid-area: 5 / 1 / 6 / 2;

    &.parallax {
      margin: 0;
      width: 100%;
    }
    + img {
      // margin-top: -25%;
    }

    &:nth-child(1){
      grid-area: \31;
      align-self: end;
    }
    &:nth-child(2){
      grid-area: \32 ;
      align-self: center;
    }
    &:nth-child(3){
      grid-area: \33 ;
      align-self: start;
    }
    &:nth-child(4){
      grid-area: \34 ;
      align-self: start;
    }
  }
}
