@import './variables.scss';


@keyframes wave {
  0%,50%,100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-17px);
    opacity: .3;
  }
}

@keyframes stretch {
  0%,100% {
    transform: translateX(0px) scale(1);
  }
  50% {
    transform: translateX(-23px) scale(1.1) rotateX(-20deg);
    // text-shadow: -10px 0px 10px rgba(50,50,50,0.3);
    // opacity: .1;
  }
}

@keyframes fadeIn {
  0% {

    opacity: 0;
  }
  0%,100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-7px);
    opacity: .3;
  }
  100% {

    opacity: 1;
  }
}

@keyframes sneaky {
  0% {

    opacity: 0.1;
  }

  50%,75% {

    opacity: 1;
  }
  100% {

    opacity: 0.1;
  }
}

@keyframes bounceIn {
  0% {
    transform: translate(0,-10px) scale(.3);
    opacity: 0;
  }
  65% {
    transform: translate(0,5px) scale(1.07);
  }
  100% {
    transform: translate(0,0px) scale(1);
    opacity: 1;
  }
}

@keyframes bounceOut {
  100% {
    transform: translate(0,-10px) scale(.7);
    opacity: 0;

  }
  25% {
    transform: translate(0,5px) scale(1.07);
  }
  0% {
    transform: translate(0,0px) scale(1);
    opacity: 1;

  }
}

.animate-letters {
  /* white-space: nowrap; */
}

.animate-letters .letter {
  display: inline-block;
  animation: sneaky 3.5s infinite $bezier;
  backface-visibility: hidden;
  /* animation: stretch 1.5s infinite ease; */
}

.animate-letters .letter--space {
  width: 0.2em;
}

.animate-letters.read .letter {
  opacity: 0;
  animation: fadeIn 1.0s 1 ease;
  animation-fill-mode: both;
  animation-play-state: paused;

  .scroll-point--active & {
    animation-play-state: running;
  }
}

.animate-letters.read.hold .letter {
  animation-play-state: paused;
  opacity: 0;
}
