
.diagrams-numbers {
  header {
    @include flex($alignX:flex-start);
    width: 100%;
    // margin-bottom: $margin-text;

    h1,h2,h3,h4 {
      align-self: flex-start;
      text-align: left;
      font-size: $font-size--large;
      font-size: 1.5rem;
      letter-spacing: 1px;

      span {
        font-size: $font-size--small;
        text-transform: capitalize;
      }
    }

  }

  + .diagrams-numbers {
    margin-top: 0;
    padding-top: 0;
  }
}

.numbers {
  list-style: none;
  margin: 0;
  padding: 0;
  @include flex($alignX:center);
  margin-bottom: $margin;

  @include mqFrom($desktop){
    @include flex($alignX:space-between);
  }

  .no-number-margin & {
    margin-bottom: 0;
  }

  li {
    margin:.5rem auto;
    @include flex($dir:column);

    &.circle {
      margin:.5rem;

      @include mqFrom($xl){
        margin:.85rem;

        .highlight {
          margin: .25rem;
        }
      }

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    &.brief {
      @include mqFrom($portrait){
        margin-top:$margin*1.2;
        margin-bottom:$margin*1.2;
      }
    }

    * {
      line-height: 1;
      margin: 0;
    }

    .tilde {
      margin: auto .3rem auto 0;
      height: 0.5rem;
      display: inline-block;
    }
  }

  .highlight {
    font-size: 3rem;
    color:$color-2023-gray--400;
    @include flex();

    @include mqFrom($portrait){
      font-size: 4rem;
    }
    @include mqFrom($xl){
      font-size: 6rem;

    }
  }
}

$animationDelay: 0.15s;
$nthDelay: .1s;


$elements: 5;
$circleBorderSize: $border-size;
$circleBorderSize: 5.5px;

.circle {
  // @include circle($border:$circleBorderSize,$radius:10rem, $background:none, $color:#fff);
  // @include circle($border:$circleBorderSize,$radius:calc(65vw/5), $background:none, $color:$color-blue);
  // @include circle($border:$circleBorderSize,$radius:calc(65vw/5), $background:none, $color:$color-blue--medium);
  @include circle($border:$circleBorderSize,$radius:calc(65vw/5), $background:none, $color:$color-2023-gray--400);
  transition: border-color .9s $bezier .3s;
  border-color: rgba($white,0);
  min-width: 30vw;
  min-height: 30vw;

  @include mqTo($portrait){
    width: 34vw;
    height: 34vw;
    margin: .1rem;
    border:4px solid;

  }

  @include mqFrom($portrait){
    min-width: 10rem;
    min-height: 10rem;

  }

  .highlight {
    letter-spacing: -4px;
    @include mqFrom($xl){
      font-size: 4.5rem;
    }
  }

  .title {
    @include mqFrom($xl){
      font-size: 2.2rem;
    }
  }

  >* {
    opacity: 0;
    transform: translateY(10px) scale(1);
    transition: all .5s ease 0s;

    .scroll-point--active & {
      transition: all 2s ease 1.5s;

      opacity: 1;
      transform: none;

      @for $i from 0 to $elements+1 {
        &:nth-child(#{$i}) {
          $newDelay: $nthDelay * $i * 2 + $animationDelay;
          transition-delay: $newDelay;
        }
      }
    }
  }

  &:before {
    pointer-events: none;
    position: absolute;
    content: "";
    top:-$circleBorderSize;
    left: -$circleBorderSize;
    right: -$circleBorderSize;
    bottom: -$circleBorderSize;
    border: $circleBorderSize solid $blue;
    border-radius: 50%;
    transition: all .9s $bezier;
    opacity: 0;
    transform: scale(1.25);
    display: none;
  }

  [class*="fade-to-"] & {
    &:before {
      display: block;
    }

    @for $i from 0 to $elements+1 {
      &:nth-of-type(#{$i}) {
        $newDelay: $nthDelay * $i + $animationDelay;
        transition-delay: $newDelay + 1.25;

        &:before {
          transition-delay: $newDelay;
        }
      }
    }
  }

  [class*="fade-to-"].scroll-point--active & {
    border-color: rgba($white,.4);
    border-color: rgba($white,0);
    // border-color: rgba($color-blue--light,1);

    &:before {
      opacity: 1;
      transform: none;
    }
  }

  .fade-to-blue & {
    &:before {
      border-color: rgba($blue,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          // $newDelay: $nthDelay*($elements - $i) + $animationDelay;
          // transition-delay: $newDelay;
          border-color: rgba($blue,math.div($i,5));
        }
      }
    }
  }
  .fade-to-blue-dark & {
    &:before {
      border-color: rgba($color-2023-blue--dark,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          // $newDelay: $nthDelay*($elements - $i) + $animationDelay;
          // transition-delay: $newDelay;
          border-color: rgba($color-2023-blue--dark,math.div($i,5));
        }
      }
    }
  }
  .fade-to-gold & {
    &:before {
      border-color: rgba($color-2023-gold,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          // $newDelay: $nthDelay*($elements - $i) + $animationDelay;
          // transition-delay: $newDelay;
          border-color: rgba($color-2023-gold,math.div($i,5));
        }
      }
    }
  }
  .fade-to-red & {
    &:before {
      border-color: rgba($red,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          border-color: rgba($red,math.div($i,10));
        }
      }
    }
  }
  .fade-to-yellow & {
    &:before {
      border-color: rgba($color-lime,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          border-color: rgba($color-lime,math.div($i,5));
        }
      }
    }
  }
  .fade-to-purple & {
    &:before {
      border-color: rgba($color-2023-purple,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          border-color: rgba($color-2023-purple,math.div($i,5));
        }
      }
    }
  }
  .fade-to-gray & {
    &:before {
      border-color: rgba($color-2023-gray--300,1);
    }

    @for $i from 0 to $elements {
      &:nth-of-type(#{$i}) {
        &:before {
          border-color: rgba($color-2023-gray--300,math.div($i,5));
        }
      }
    }
  }
}
//
// @keyframes highlight {
//   0%,100% {
//     transform: none;
//   }
//   25% {
//     transform: translateY(-5px);
//
//   }
// }
// @keyframes highlight {
//   0%,100% {
//     opacity: 1;
//     transform: none;
//
//   }
//   25% {
//     opacity: .7;
//     transform: scale(.95);
//     // transform: rotateY(20deg);
//     // transform: scale(.95) translateY(-5px);
//
//
//   }
// }
// @keyframes highlight {
//   0%,100% {
//     color:$black;
//     transform: none;
//     text-shadow: 0 0px 0px rgba($black,.3);
//
//   }
//   25%,50% {
//     color:$red;
//     color:$blue--dark;
//     transform: translateY(-5px) scale(1.1);
//     text-shadow: 0 4px 4px rgba($black,.3);
//   }
// }
//
// @keyframes highlightText {
//   0%,100% {
//     transform: none;
//   }
//   25%,50% {
//     transform: translateY(5px);
//   }
// }
//
//
// $elements:8;
// $animationDelay:.5s;
//
// .brief {
//   width: calc(100%/3);
//   opacity: 0;
//   transform: translateY(30px);
//   transition: all 1s ease;
//
//   .scroll-point--active & {
//     opacity: 1;
//     transform: none;
//   }
//   &:hover {
//     .highlight {
//       color:$blue--dark;
//       transform: translateY(-5px) scale(1.1);
//       text-shadow: 0 4px 4px rgba($black,.3);
//
//       ~ * {
//         transform: translateY(5px);
//       }
//     }
//   }
//
//   @for $i from 0 to $elements {
//     &:nth-of-type(#{$i}) {
//       $newDelay: $nthDelay* $i + $animationDelay;
//
//       .scroll-point--active & {
//         transition-delay: $newDelay;
//       }
//
//       .highlight {
//         line-height: 1.3;
//
//         .highlight & {
//           animation-delay: 10 * $newDelay;
//           animation-delay: 5 * $newDelay;
//
//           ~ * {
//             animation-delay: 5 * $newDelay;
//           }
//         }
//       }
//     }
//   }
//
//   .highlight {
//     line-height: 1.3;
//     transition: all .3s $bezier;
//     text-shadow: 0 0px 0px rgba($black,.3);
//
//     ~ * {
//       transition: all .5s ease;
//     }
//
//     .highlight & {
//       animation: highlight 1.2s 1 0.5s;
//       // animation: highlight 2s infinite 2.5s;
//       ~ * {
//         animation: highlightText 1.2s 1 0.5s;
//
//       }
//
//     }
//   }
//
//   .title {
//     // font-size: .8rem;
//     @include fontBold();
//     font-size: $font-size--mini;
//   }
//
//   .end {
//     font-size: .9rem;
//     margin-top: .3rem;
//
//     b {
//       font-family: $font-bold;
//     }
//   }
//
// }
