@use "sass:math";

@import './styles/base.scss';
@import './styles/layout.scss';
@import './styles/articles.scss';
@import './styles/Svg.scss';
@import './styles/numbers.scss';

@import './styles/Menu.scss';
@import './styles/footer.scss';


.page-wrapper {
  @include flex();
  &.single-page-open {
    overflow: hidden;
  }
}

[class*="page"]{
  width: 100%;
  min-height: 100vh;
  @include flex($dir:column, $alignY:flex-start);

  // transition: transform .4s $bezier .15s, clip-path .6s $bezier 0s, opacity .3s ease .55s;
  transition: transform .9s $bezier .2s, clip-path .9s $bezier 0s, opacity .3s ease 0s, filter 1s ease;


  .single-page-open & {
    // transition: transform .4s $bezier, clip-path .6s $bezier .1s;
    transition: transform .6s $bezier, clip-path .6s $bezier .3s, filter 1s ease;

    transform:translate(-30%,0) scale(.8);
    // transform:translate(-30%,0) scale(.8) rotateY(60deg);
    // opacity: 0;


  }

}

#page {
  overflow-x: hidden;
}

main.page {
  @include mqFrom($landscape){
    .menu-is-open & {
      transform: scale(1.15);
      // transform: rotateZ(30deg);
      filter: blur(10px);
      filter: opacity(10%) blur(10px);
    }
  }
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {

  &:after, &:before {
    transition: all 1s ease;
    position: fixed;
    pointer-events: none;
    content: "";
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    @include flex();
    background: $blue--dark;
    background: $color-lime;
    background: $color-2023-blue;
    z-index: 9999;
    color: #fff;
  }

  &:after {
    width: 75px;
    height: 75px;
    border-top:4px solid #fff;
    border-left:4px solid #fff;
    border-bottom:4px solid #fff;
    border-right:4px solid transparent;
    border-radius: 50%;
    background: none;
    margin: auto;
    animation: loader 1s infinite linear;
  }

  &.loading {
    &:after, &:before {
      opacity: 1;
    }
  }
}
.single-page-open main {
  overflow: hidden;
  filter: blur(25px);
}

.page {

  &--single {
    position: fixed;
    top:0;
    bottom: 0;
    right: 0;
    transform:translate(100%,0);
    transform:translate(100vw,0);
    background: $color-bg--article;
    color: #f5f5f5;
    color: $color-text;
    clip-path: circle(60px at center);
    overflow: auto;
    z-index: 250;
    box-shadow: -10px 0 100px #333;
    padding-bottom:150px;

    article {
      opacity: 0;
    }
    .single-page-open & {
      transform: none;
      clip-path: circle(100% at center);
      opacity: 1;

    }
  }
}



// .w- {
//   &25 {
//     width: 25%;
//     flex:auto !important;
//   }
// }



.ratio-{
  &1-1 {
    .col {
      width: 50%;
    }
  }
  @include mqFrom($portrait){
    &2-1 {
      .col {
        &:nth-child(2n + 1){
          width: calc(100%/3.0 * 2);
        }
        &:nth-child(2n + 2){
          width: calc(100%/3);
        }
      }
    }
    &1-2 {
      .col {
        &:nth-child(2n + 2){
          width: calc(100%/3 * 2);
        }
        &:nth-child(2n + 1){
          width: calc(100%/3);
        }
      }
    }
  }
}



.cols {
  @include flex($dir:column);

  .col {
    // flex:1;
    &-flex {
      flex:1;
      max-width: 100%;
    }
  }


  > *:not(figure) {
    padding: $padding;
  }

  figure + .col {
    padding-left: $padding*2;
  }

  @include mqFrom($portrait){
    @include flex();
  }
}

.skew {
  &--left {
    > * {
      &:first-child {
        transform: translateY(15%);
      }
      &:last-child {
        // transform: translateY(25%);
        margin-bottom: 15%;

      }
    }
  }
}

.scrollable {
  max-width: 80%;

  @include mqFrom($portrait){
    max-width: 500px;

  }

  + .scrollable {
    margin-top: -15%;

    .enviroment-section & {
      margin-top: 0;

      @include mqFrom($portrait){
        margin-top: -15%;
      }
    }

    &:nth-of-type(even){
      // align-self: flex-end;
    }
    &:last-of-type{
      margin-bottom: 15%;
    }
  }
}
