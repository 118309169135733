

.main-footer {
  width: 100%;
  @include flex($dir:column);
  padding: $padding;
  background: $gray;
  color: $white;

  .title--large {

    @include mqFrom($desktop){
      font-size: calc(2rem + 10vw);
      font-size: 11vw;
    }
  }

  .logo {
    width: 100px;
    display: block;
    margin-bottom: $padding*.5;

    @include mqFrom($portrait){
      width: 150px;
    }
  }

  .inner {
    margin-bottom: $padding*1.5;

    // > *:last-child {
    //
    //   margin: 0;
    // }
  }

  .download {
    @include flex();
    padding: $margin $padding*1.5 ;
    border:$line-size solid;
    max-width: 450px;
    margin: auto;  
    transition: background .2s ease; 
    
    &:hover {
      background: rgba($blue,0.2);
      background: rgba($color-2023-blue--dark,0.2);
    }

    h3,h4 {
      margin: auto auto auto 0;
      font-size: 1.1rem;
      font-family:inherit;
      text-transform: none;
      line-height: 1.4;

      @media 
      (-webkit-min-device-pixel-ratio: 2), 
      (min-resolution: 192dpi) { 
          /* Retina-specific stuff here */
          // font-size: $font-size--small;
          font-size: 1rem;
      }

      span {
        @include fontTitle($size: 1.3rem);
        text-transform: none;
        letter-spacing: 1px;

        @media 
        (-webkit-min-device-pixel-ratio: 2), 
        (min-resolution: 192dpi) { 
            /* Retina-specific stuff here */
            // font-size: $font-size--small;
            font-size: 1.2rem;
            letter-spacing: initial;
        }

      }
    }

    span {
      display: block;
    }
  }

  .end {
    padding: $padding;
    @include flex($dir:column);
    width: 100%;
    border-top: $line-size solid;
    padding-top: $margin;

    a {
      margin: 0;
    }
  }
}
