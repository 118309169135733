@font-face {
  font-family: "Avenir";
  src: url("../fonts/Avenir.ttc") format("ttc");
}

@font-face {
  font-family: "MinionPro";
  src: url("../fonts/MinionPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Gara";
  src: url("../fonts/Garamond3LTStd.otf") format("opentype");
}

@font-face {
  font-family: "GaraBol";
  src: url("../fonts/Garamond3LTStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: "GaraBolIta";
  src: url("../fonts/Garamond3LTStd-BoldItalic.otf") format("opentype");
}

// @import 'https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/1c557856-58ec-4541-9452-7acb5ffe2c32.woff2';
// @import 'https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/21d611aa-91cd-462c-9896-b71b85d3cca9.woff2';
// @import 'https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/2fa93a7f-a137-4181-88fb-baa48002311b.woff2';
// @import 'https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/46d9ac88-dbbb-4a0e-afa2-aa57045a2d12.woff2';
//
// @font-face {
//   font-family: "Title";
//   src: url("https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/1c557856-58ec-4541-9452-7acb5ffe2c32.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Title";
//   src: url("https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/21d611aa-91cd-462c-9896-b71b85d3cca9.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Title";
//   src: url("https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/2fa93a7f-a137-4181-88fb-baa48002311b.woff2") format("woff2");
// }
// @font-face {
//   font-family: "Title";
//   src: url("https://cors-anywhere.herokuapp.com/https://www.troax.com/themes/troax/build/fonts/46d9ac88-dbbb-4a0e-afa2-aa57045a2d12.woff2") format("woff2");
// }
