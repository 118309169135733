@import './base.scss';

.tabs {
  width: 100%;
  padding: $margin 0;

  @include mqFrom($portrait){
    padding: $margin;

  }

  &__nav {
    margin-bottom: $padding;

    &__tab {
      color:$blue;
      line-height: 1;
      margin: 0;
      cursor: pointer;

      &.active {
        color: $black;
      }
      &:hover {
        color: $blue--dark;
      }
    }
  }

  &__content {

    &__tab {
      display: none;

      &.active {
        display: inherit;
      }
    }
  }
}
